import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import chevronDownSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-down-small";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";
import { nyrOver,  ambientColor, chilledColor, frozenColor, rolledFreightColor} from "../Constants";


import { MainAPI } from '../MainApi';


function RolledFreightPalletHeader() {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell>Pallet Id</TableCell>
                <TableCell>Hours Rolled</TableCell>
            </TableRow>
        </React.Fragment>
    )
};

function RolledFreightPalletRows({data, metrickey}) {
    return (
        <React.Fragment>
            {data.map((pallet, index)=> {
                    console.log(pallet);
                    const palletId = pallet["container"] ? pallet["container"] : ""
                    const hoursRolled = pallet["hours_rolled"] ? pallet["hours_rolled"] : ""
                    return (
                        <React.Fragment>
                            <TableRow>
                                <TableCell backgroundColor={rolledFreightColor}>{palletId}</TableCell>
                                <TableCell backgroundColor={rolledFreightColor}>{hoursRolled}</TableCell>
                            </TableRow>
                        </React.Fragment>
                    )
                })}
        </React.Fragment>
    )
};

function RolledFreightPalletTable({data}) {

    return (
        <Table headerRows={1} showDividers={true} rowComponents={[RolledFreightPalletHeader, RolledFreightPalletRows]}>
            <RolledFreightPalletHeader/>
            <RolledFreightPalletRows data={data}/>
        </Table>
    )
};


function RolledFreightAreas({data, rowName, expandedRows, updateExpandedRows}) {
    return (
        <Table>
            {data.map((areaData, index)=> {
                console.log(areaData)
                const area = areaData["area"] ? areaData["area"] : "?"
                const pallets = areaData["pallets"] ? areaData["pallets"] : []
                const areaRowName = rowName + area
                return (
                    <React.Fragment>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(areaRowName) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(areaRowName)}
                                >
                                    {expandedRows.includes(areaRowName) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell>{area}</TableCell>
                        </TableRow>
                        {
                            expandedRows.includes(areaRowName) && (
                                <TableRow><RolledFreightPalletTable data={pallets}/></TableRow>
                            )
                        }
                    </React.Fragment>
                )

            })}
        </Table>
    )
};

function RolledFreightProcessing({data, rowName, expandedRows, updateExpandedRows}) {
    return (
        <Table>
            {data.map((areaData, index)=> {
                const processingType = areaData["processing_type"] ? areaData["processing_type"] : "?"
                const areas = areaData["areas"] ? areaData["areas"] : []
                const processingRowName = rowName + processingType
                return (
                    <React.Fragment>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(processingRowName) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(processingRowName)}
                                >
                                    {expandedRows.includes(processingRowName) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell>{processingType}</TableCell>
                        </TableRow>
                        {
                            expandedRows.includes(processingRowName) && (
                                <TableRow><RolledFreightAreas data={areas} rowName={processingRowName} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows}/></TableRow>
                            )
                        }
                    </React.Fragment>
                )

            })}
        </Table>
    )
};


function RolledFreightDetailsTable({data, rowName, expandedRows, updateExpandedRows}) {
    return (
        <Table>
            {data.map((tempZoneData, index)=> {
                const tempZone = Object.keys(tempZoneData)[0] ? Object.keys(tempZoneData)[0] : "?"
                const rolledPallets = tempZoneData[tempZone]["pallet_count"] ? tempZoneData[tempZone]["pallet_count"] : 0
                const processingTypes = tempZoneData[tempZone]["data"] ? tempZoneData[tempZone]["data"] : 0
                const detailName = rowName + tempZone
                return (
                    <React.Fragment>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(detailName) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(detailName)}
                                >
                                    {expandedRows.includes(detailName) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Column>{tempZone}</Column>
                                <Column>{rolledPallets}</Column>
                            </TableCell>
                        </TableRow>
                        {
                            expandedRows.includes(detailName) && (
                                <TableRow><RolledFreightProcessing data={processingTypes} rowName={detailName} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows}/></TableRow>
                            )
                        }
                    </React.Fragment>
                )

            })}
        </Table>
    )
};


function TableHeader() {
    return (
        <TableRow>
            <TableCell/>
            <TableCell alignmentHorizontal={"center"}>Spoke</TableCell>
            <TableCell alignmentHorizontal={"center"}>Trailer Size</TableCell>
            <TableCell alignmentHorizontal={"center"}>CPT</TableCell>
            <TableCell alignmentHorizontal={"center"}>Ambient NYR</TableCell>
            <TableCell alignmentHorizontal={"center"}>Ambient Total</TableCell>
            <TableCell alignmentHorizontal={"center"}>Chilled NYR</TableCell>
            <TableCell alignmentHorizontal={"center"}>Chilled Total</TableCell>
            <TableCell alignmentHorizontal={"center"}>Frozen NYR</TableCell>
            <TableCell alignmentHorizontal={"center"}>Frozen Total</TableCell>
            <TableCell alignmentHorizontal={"center"}>Total Rolled Freight</TableCell>
            <TableCell alignmentHorizontal={"center"}>Total Pallets</TableCell>
        </TableRow>
    )
}

function RolledFreightRows({rolledFreight}) {
    const [expandedRows, setExpandedRows] = useState([])
    const updateExpandedRows = row => {
        const newExpandedRows = expandedRows.includes(row)
            ? expandedRows.filter(expandedRow => expandedRow !== row)
            : [...expandedRows, row]
        setExpandedRows(newExpandedRows)
    }
    return (
        <React.Fragment>
            {rolledFreight.map((rolledFreightItem, index)=>{
                const spoke = rolledFreightItem["spoke"] ? rolledFreightItem["spoke"] : ""
                const truckSize = rolledFreightItem["equipment_type"] ? rolledFreightItem["equipment_type"] : ""
                const cpt = rolledFreightItem["cpt_local"] ? rolledFreightItem["cpt_local"] : ""
                const ambientNYR = rolledFreightItem["ambient_nyr_space"] ? rolledFreightItem["ambient_nyr_space"] : 0
                const ambientTotal = rolledFreightItem["total_ambient_pallets"] ? rolledFreightItem["total_ambient_pallets"] : 0
                const chilledNYR = rolledFreightItem["chilled_nyr_space"] ? rolledFreightItem["chilled_nyr_space"] : 0
                const chilledTotal = rolledFreightItem["total_chilled_pallets"] ? rolledFreightItem["total_chilled_pallets"] : 0
                const frozenNYR = rolledFreightItem["frozen_nyr_space"] ? rolledFreightItem["frozen_nyr_space"] : 0
                const frozenTotal = rolledFreightItem["total_frozen_pallets"] ? rolledFreightItem["total_frozen_pallets"] : 0
                const totalRolledFreight = rolledFreightItem["total_rolled_freight"] ? rolledFreightItem["total_rolled_freight"] : 0
                const totalPallets = rolledFreightItem["total_pallets"] ? rolledFreightItem["total_pallets"] : 0
                const ambientBackgroundColor = ambientNYR >= ambientTotal ? ambientColor : nyrOver
                const chilledBackgroundColor = chilledNYR >= chilledTotal ? chilledColor : nyrOver
                const frozenBackgroundColor = frozenNYR >= frozenTotal ? frozenColor : nyrOver
                const rowName = [spoke, cpt].join("-")
                const data = rolledFreightItem["data"] ? rolledFreightItem["data"] : []
                return (
                    <React.Fragment>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(rowName) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(rowName)}
                                >
                                    {expandedRows.includes(rowName) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell alignmentHorizontal={"center"}>{spoke}</TableCell>
                            <TableCell alignmentHorizontal={"center"}>{truckSize}</TableCell>
                            <TableCell alignmentHorizontal={"center"}>{cpt}</TableCell>
                            <TableCell alignmentHorizontal={"center"} backgroundColor={ambientBackgroundColor}>{ambientNYR}</TableCell>
                            <TableCell alignmentHorizontal={"center"} backgroundColor={ambientBackgroundColor}>{ambientTotal}</TableCell>
                            <TableCell alignmentHorizontal={"center"} backgroundColor={chilledBackgroundColor}>{chilledNYR}</TableCell>
                            <TableCell alignmentHorizontal={"center"} backgroundColor={chilledBackgroundColor}>{chilledTotal}</TableCell>
                            <TableCell alignmentHorizontal={"center"} backgroundColor={frozenBackgroundColor}>{frozenNYR}</TableCell>
                            <TableCell alignmentHorizontal={"center"} backgroundColor={frozenBackgroundColor}>{frozenTotal}</TableCell>
                            <TableCell alignmentHorizontal={"center"}>{totalRolledFreight}</TableCell>
                            <TableCell alignmentHorizontal={"center"}>{totalPallets}</TableCell>
                        </TableRow>
                        {
                            expandedRows.includes(rowName) && (
                                <TableRow><RolledFreightDetailsTable data={data}  rowName={rowName} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows}/></TableRow>
                            )
                        }
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    )
}

// Properly format the data for the totalsRow
function TotalsRowProcessingAreaData(data) {

    let rolled_freight_temp_zones = {};

    // We iterate a very nested object. Its spoke -> temp zone -> processing type -> processing area and we grab the pallets.
    // WE do this because processing types and areas can be duplicated over spokes and we want to combine them in the total to make it cleaner.
    // Use nested objects to deduplicate and then at the end reformat the data structure to what the front end is expecting.

    // For each spoke
    for(let r of data){

        // For each spokes temp zone
        for(let temp_zone_obj of r.data){

            // For all entries in the temp zone
            for (const key of Object.keys(temp_zone_obj)) {
                // If temp zone exists in object then do nothing, else give default val of empty object
                rolled_freight_temp_zones[key] = rolled_freight_temp_zones[key] || {'data': {}, pallet_count: 0}

                rolled_freight_temp_zones[key].pallet_count += temp_zone_obj[key].pallet_count

                // For each processing type of the temp zone
                for(let processing_type of temp_zone_obj[key].data){

                    rolled_freight_temp_zones[key].data[processing_type.processing_type] = rolled_freight_temp_zones[key].data[processing_type.processing_type] || {'areas': {}}


                    // for each area of the processing type
                    for(let area of processing_type.areas){

                        if(area.area in rolled_freight_temp_zones[key].data[processing_type.processing_type].areas){
                            rolled_freight_temp_zones[key].data[processing_type.processing_type].areas[area.area].push(...area.pallets)
                        }
                        else {
                            // Use deep copy. Else this causes identical area pallets to appear in future CPT's
                            rolled_freight_temp_zones[key].data[processing_type.processing_type].areas[area.area] = [];
                            rolled_freight_temp_zones[key].data[processing_type.processing_type].areas[area.area].push(...area.pallets);
                        }
                    }
                }
            }
        }

        
    }

    // reformat totals data to what the front end expects.
    let output = []
    for (const [key, value] of Object.entries(rolled_freight_temp_zones)){
        let new_obj = {}

        new_obj[key] = {}

        let new_obj_val = {data: [], pallet_count: value.pallet_count}

        for (const [processing_type_key, processing_type_value] of Object.entries(value.data)){

            let new_processing_type_obj = {processing_type: processing_type_key, 'areas': []}

            for (const [processing_area_key, processing_area_value] of Object.entries(processing_type_value.areas)){
                new_processing_type_obj.areas.push({area: processing_area_key, pallets: processing_area_value})
            }

            new_obj_val.data.push(new_processing_type_obj)

        }


        new_obj[key] = new_obj_val
        output.push(new_obj)
    }

    return output

}

function RolledFreight() {
    const api = new MainAPI();
    const { warehouseId } = useParams();
    useEffect(() => {
        setRolledFreight([]);
    }, []);

    useEffect(() => {
        const fetchRolledFreight = async () => {
            const data = await api.getRolledFreight(warehouseId);
            if (data) {
                const emptyRow = {
                    "spoke": "",
                    "equipment_type": "",
                    "cpt_local": "",
                    "ambient_nyr_space": " ",
                    "total_ambient_pallets": " ",
                    "chilled_nyr_space": " ",
                    "total_chilled_pallets": " ",
                    "frozen_nyr_space": " ",
                    "total_frozen_pallets": " ",
                    "total_rolled_freight": " ",
                    "total_pallets": " "
                    
                }

                // Final row will be a total row that sums all the spokes values
                let totalsRow = {
                    "spoke": "TOTAL",
                    "equipment_type": "",
                    "cpt_local": "",
                    "ambient_nyr_space": 0,
                    "total_ambient_pallets": 0,
                    "chilled_nyr_space": 0,
                    "total_chilled_pallets": 0,
                    "frozen_nyr_space": 0,
                    "total_frozen_pallets": 0,
                    "total_rolled_freight": 0,
                    "total_pallets": 0,
                    "data": []
                    
                };

                let used_spokes_nyr = new Set()

                for(let r of data){
                    totalsRow.total_ambient_pallets += r.total_ambient_pallets;
                    totalsRow.total_chilled_pallets += r.total_chilled_pallets;
                    totalsRow.total_frozen_pallets += r.total_frozen_pallets;
                    totalsRow.total_rolled_freight += r.total_rolled_freight;
                    totalsRow.total_pallets += r.total_pallets;

                    // We don't want to duplicate NYR values for a spoke, so only add to total's once
                    if(!used_spokes_nyr.has(r.spoke)){
                        totalsRow.ambient_nyr_space += r.ambient_nyr_space;
                        totalsRow.chilled_nyr_space += r.chilled_nyr_space;
                        totalsRow.frozen_nyr_space += r.frozen_nyr_space;
                        used_spokes_nyr.add(r.spoke);
                    }
                }

                totalsRow.data = TotalsRowProcessingAreaData(data)
                data.push(totalsRow);

                // Empty rows to not have footer overlap
                // This is a quick fix
                data.push(emptyRow)
                data.push(emptyRow)
                data.push(emptyRow)

                setRolledFreight(data);
            }
        }
        fetchRolledFreight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseId]);

    const [rolledFreight, setRolledFreight] = useState([]);
    return (
        <Table headerRows={1} showDividers={true}  rowComponents={[TableHeader, RolledFreightRows]}>
            <TableHeader/>
            <RolledFreightRows rolledFreight={rolledFreight}/>
        </Table>
    )
}

export default RolledFreight;