import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { MainAPI } from './MainApi';

import irisLogo from './irisLogo.png'


function Navigation() {
  const api = new MainAPI();
  const location = useLocation();
  const [navSuffix, setNavSuffix] = useState('');
  const [warehouseIds, setWarehouseIds] = useState([]);
  const [stage, setStage] = useState('');

  useEffect(() => {
    // runs on location, i.e. route, change
    setNavSuffix(location.pathname.split('/').slice(2).join('/'));
    if (window.location.hostname.includes('dev-dsk') || window.location.hostname.includes('aka.corp')) {
      setStage('-Dev');
    } else if (window.location.hostname.includes('beta')) {
      setStage('-Beta')
    }
    else {
      setStage('');
    }
  }, [location])

  useEffect(() => {
    setWarehouseIds([]);
  }, []);

  useEffect(() => {
    const fetchWarehouseIds = async () => {
      const data = await api.getWarehouseIds();
      if (data) {
        setWarehouseIds(data.map((d) => <NavDropdown.Item href={"/" + d + navSuffix}>{ d }</NavDropdown.Item>));
      }
    }
    fetchWarehouseIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const paths = useLocation().pathname.split('/');

  var warehouseId = '';
  // 2 because splitting on / causes an empty '' on item 0
  if (paths.length >= 2) {
    warehouseId = paths[1];
  }



  return (
    <Navbar bg="light" expand="lg" variant="light">
      <Container fluid>
        <Container>
          <Navbar.Brand href="/#">
            <img
              alt=""
              src={ irisLogo }
              width="75"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            {"King's Cross" + stage}
          </Navbar.Brand>
        </Container>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
            >
              <NavDropdown title={ warehouseId } id="navbarScrollingDropdown">
                { warehouseIds }
              </NavDropdown>

            </Nav>
          </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
