import { API } from 'aws-amplify';

const apiName = 'obmmrestapi';

// Get the correct amplify stage exports
let stage = 'prod';

if (window.location.hostname.includes('dev-dsk') || window.location.hostname.includes('aka.corp') || window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) {
    stage = 'dev';
    } else if (window.location.hostname.includes('beta')) {
    stage = 'beta';
    }

class MainAPI {

    // stage = window.location.hostname.includes('dev-dsk') || window.location.hostname.includes('aka.corp') || window.location.hostname.includes('beta') ? 'Dev' : 'Prod';


    
    getWarehouseIds() {
        console.log(apiName);
        return API.get(apiName, '/getCPTChaserWarehouseids', {});
    }

    getTempZones(warehouseId) {
        console.log(apiName);
        // return API.get(apiName, '/tempZones', {'queryStringParameters': {'warehouse_id': warehouseId}});
        return ["AMBIENT", "CHILLED", "FROZEN"];
    }

    getCPTs(warehouseId, tempZone) {
        console.log(apiName);
        return API.get(apiName, '/getCPTs', {
            'queryStringParameters': {
                'warehouse_id': warehouseId,
                "temp_zone": tempZone,
                "stage": stage
            }
        });
    }

    getCPT(lane, cpt, tempZone) {
        console.log(apiName);
        return API.get(apiName, '/getCPT', {
            'queryStringParameters': {
                'lane': lane,
                "cpt": cpt,
                "temp_zone": tempZone,
                "stage": stage
            }
        });
    }

    getDockDoors(warehouseId) {
        console.log(apiName);
        return API.get(apiName, '/getDockDoors', {'queryStringParameters': {'warehouse_id': warehouseId}})
    }

    requestDockDoor(warehouseId, vrid, dockDoor) {
        console.log(apiName);
        return API.get(apiName, '/requestDockDoor', {
            'queryStringParameters': {
                'warehouse_id': warehouseId,
                'vrid': vrid,
                'dock_door': dockDoor
            }
        })
    }

    recordTrailerTemp(warehouseId, vrid, recordedTemp) {
        console.log(apiName);
        return API.get(apiName, '/trailerTempAudit', {
            'queryStringParameters': {
                'warehouse_id': warehouseId,
                'vrid': vrid,
                'recorded_temperature': recordedTemp
            }
        })
    }


    getDockDoorRequests(warehouseId) {
        console.log(apiName);
        return API.get(apiName, '/dockDoorRequests', {'queryStringParameters': {'warehouse_id': warehouseId}});
    }


    getRolledFreight(warehouseId) {
        console.log(apiName);
        return API.get(apiName, '/rolledFreight', {'queryStringParameters': {'warehouse_id': warehouseId, 'stage': stage}});
    }

    getNotifications(warehouseId) {
        console.log(apiName);
        return API.get(apiName, '/getNotifications', {'queryStringParameters': {'warehouse_id': warehouseId, 'stage': stage}});
    }
}

export { MainAPI };