// Place any apps we want to link to here
import cptChaserIcon from './cptChaser/cptChaserIcon.png'
import dockDoorRequestsIcon from './dockDoorRequests/dockDoorRequestsIcon.png'
import notificationsIcon from './notifications/notificationsIcon.png'
import rolledFreightIcon from './rolledFreight/rolledFreightIcon.png'



const AppMap = {
    "cptChaser": {"name": "CPT Chaser", "route": "/cptChaser", "image": cptChaserIcon},
    "dockDoorRequests": {"name": "Dock Door Requests", "route": "/dockDoorRequests", "image": dockDoorRequestsIcon},
    "notifications": {"name": "Notifications", "route": "/notifications", "image": notificationsIcon},
    "rolledFreight": {"name": "Rolled Freight", "route": "/rolledFreight", "image": rolledFreightIcon}
}

export default AppMap;
