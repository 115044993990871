/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5d7a8c90-40cc-4d91-a1f2-dbaf51ea22d8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FLEWDA9jP",
    "aws_user_pools_web_client_id": "774ct9h35audle3fi91d8kd4rh",
    "oauth": {
        "domain": "iris-obmm-midway-beta.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev-dsk-kcrich-1e-696087e8.us-east-1.amazon.com:3000/,https://iris-obmm.gsf.amazon.dev/,https://beta.iris-obmm.gsf.amazon.dev/",
        "redirectSignOut": "https://dev-dsk-kcrich-1e-696087e8.us-east-1.amazon.com:3000/,https://iris-obmm.gsf.amazon.dev/,https://beta.iris-obmm.gsf.amazon.dev/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "obmmrestapi",
            "endpoint": "https://l24y9p07bi.execute-api.us-east-1.amazonaws.com/beta",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "6f1b549e04894add9d9335ade9d06967",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
