import React from "react";
import { useLocation } from 'react-router-dom';
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";

const OBMMBreadcrumbs = () => {
    let breadcrumbs: any[] = [];
    const paths = useLocation().pathname.split('/');
    let fullPath = '';
    for (let index in paths) {
        const path = paths[index];
        if (path !== '') {
            fullPath += "/" + path;
            breadcrumbs.push(<Breadcrumb href={fullPath}>{path}</Breadcrumb>);
        }
        else {
            breadcrumbs.push(<Breadcrumb href={"/"}>Home</Breadcrumb>)
        }

    };
    return (
        <BreadcrumbGroup>
            {breadcrumbs}
        </BreadcrumbGroup>
    )
}

export default OBMMBreadcrumbs;