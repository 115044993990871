/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9c2935b8-c760-4fad-95df-fc30a046b1b4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_iTCj17AJ9",
    "aws_user_pools_web_client_id": "3svjebp4nvs52tur2ovcdmita9",
    "oauth": {
        "domain": "iris-obmm-midway-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev-dsk-kcrich-1e-696087e8.us-east-1.amazon.com:3000/,https://iris-obmm.gsf.amazon.dev/,https://beta.iris-obmm.gsf.amazon.dev/",
        "redirectSignOut": "https://dev-dsk-kcrich-1e-696087e8.us-east-1.amazon.com:3000/,https://iris-obmm.gsf.amazon.dev/,https://beta.iris-obmm.gsf.amazon.dev/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "obmmrestapi",
            "endpoint": "https://rxrlcwxm47.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "1d2769806cc749ccab7165dd18214bee",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
