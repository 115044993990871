import { Link, useLocation } from 'react-router-dom';
import AppMap from './AppMap'
import '../App.css';


function createAppButton(app, warehouseId) {
    const appName = AppMap[app]["name"]
    const appImage = AppMap[app]["image"]
    const appRoute = AppMap[app]["route"]
    return (
        <Link to={ "/" + warehouseId + appRoute }>
            <button className="click p-5 ms-3">{ appName }<br/>
                <img src={ appImage } alt={ appName } width="75px" height="75px"/>
            </button>
        </Link>
    )
}


function OBMMSelectApp() {
    const paths = useLocation().pathname.split('/');
    var warehouseId = '';
    // 2 because splitting on / causes an empty '' on item 0
    if (paths.length >= 2) {
        warehouseId = paths[1];
    }

    let Buttons: any[] = [];
    for (let key in AppMap) {
        Buttons.push(createAppButton(key, warehouseId));
    }
    return (
        <div className="App">
            { Buttons }
        </div>
    );
}

export default OBMMSelectApp;