export const s3Bucket = 'gsf-firebolt-app-files212421-dev';
export const s3Region = 'us-east-1';
export const emptyAuthDetails = {
    username: "",
    email: ""
}

export const rolledFreightColor = "#ffff99";
export const nearCPTColor = "#ff0000";

// NYR

export const nyrOver = "#ff6699";
export const ambientColor = "#ccffcc";
export const chilledColor = "#ccffff";
export const frozenColor = "#ccccff";
