import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer"
import Navigation from "./components/Navigation"
import Legend from "./components/Legend"
import 'bootstrap/dist/css/bootstrap.min.css';
import "@amzn/meridian-tokens/base/font/amazon-ember.css"
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import sentryFetch from "@amzn/sentry-fetch";
import {v4 as uuidv4} from 'uuid';

// CSS
import './App.css';

import OBMMBreadcrumbs from "./components/OBMMBreadcrumbs"

// routes
import OBMMHome from "./components/OBMMHome";
import OBMMSelectApp from "./components/OBMMSelectApp"
import { cptChaserRoutes } from "./components/cptChaser/cptChaserRoutes";
import DockDoorRequests from "./components/dockDoorRequests/dockDoorRequests";
import Notifications from "./components/notifications/notifications";
import RolledFreight from "./components/rolledFreight/rolledFreight";

// Amplify
import awsExportsDev from './awsExports/dev/aws-exports';
import awsExportsBeta from './awsExports/beta/aws-exports';
import awsExportsProd from './awsExports/prod/aws-exports';
import Amplify, { Auth, Analytics, API } from "aws-amplify";
import { authenticateAmplify } from "./components/auth/midwayAuth";
import type { AuthDetails } from "./components/auth/midwayAuth";


// Get the correct amplify stage exports
let stage = 'prod';

if (window.location.hostname.includes('dev-dsk') || window.location.hostname.includes('aka.corp') || window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) {
  stage = 'dev';
} else if (window.location.hostname.includes('beta')) {
  stage = 'beta';
}

console.log(stage);

// We're mapping our backend stages to match our frontend
const awsMap = { dev: awsExportsDev, beta: awsExportsBeta, prod: awsExportsProd };

const awsExports = awsMap[stage as keyof typeof awsMap];

export let awsRum: AwsRum | undefined = undefined;

// Good example
// https://code.amazon.com/packages/ACSSafeWebsite/blobs/mainline/--/src/hooks/useRum.ts

if (stage !== 'dev'){
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::336473673731:role/amplify-gsfirisobmaraudersma-prod-05656-unauthRole",
      identityPoolId: "us-east-1:2a62b451-c51d-4db2-9062-4e08e8631d1d",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: true
    };

    const APPLICATION_ID: string = '43a4359c-39ae-4ec9-ae87-175e77d791fa';
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-east-1';

    awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );

    // Useful to determining stage this event came from
    awsRum.addSessionAttributes({
      stage: stage
  });
    console.log(awsRum);

  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
    console.log(error);
  }
}


// Place your routes here
const OBMMRoutes = (
    <div>
      <Navigation />
      <OBMMBreadcrumbs />
      <Legend />
      <div className={"content"}>
      <Routes key="routes">
        <Route key="1" path="/" element={<OBMMHome />} />
        <Route key="2" path="/:warehouseId" element={<OBMMSelectApp />} />
        { cptChaserRoutes }
        <Route key="3" path="/:warehouseId/dockDoorRequests" element={<DockDoorRequests />} />
        <Route key="4" path="/:warehouseId/notifications" element={<Notifications />} />
        <Route key="5" path="/:warehouseId/rolledFreight" element={<RolledFreight />} />
      </Routes>
      </div>
      <Footer />
    </div>
);

const baseUrl = "iris-obmm.gsf.amazon.dev/"

if (window.location.hostname.includes('dev-dsk') || window.location.hostname.includes('aka.corp')) {
  awsExports.oauth.redirectSignIn = 'https://' + window.location.hostname + ':3000/';
  awsExports.oauth.redirectSignOut = 'https://' + window.location.hostname + ':3000/';
} else if (window.location.hostname.includes('beta')) {
  awsExports.oauth.redirectSignIn = 'https://beta.' + baseUrl;
  awsExports.oauth.redirectSignOut = 'https://beta.' + baseUrl;
}
else {
  awsExports.oauth.redirectSignIn = 'https://' + baseUrl;
  awsExports.oauth.redirectSignOut = 'https://' + baseUrl;
}

Amplify.configure(awsExports);
Auth.configure(awsExports);
API.configure(awsExports);
Analytics.configure(awsExports);

Analytics.autoTrack('session', {
	enable: true,
	immediate: true,
    provider: "AWSPinpoint"
});

Analytics.autoTrack('pageView', {
  enable: true,
  type: 'SPA',
  immediate: true,
  provider: "AWSPinpoint"
});

Analytics.autoTrack('event', {
  enable: true,
  immediate: true,
  provider: "AWSPinpoint"
});

// This will force all routes to be midway authed before proceding
export const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const authUser = async () => {
      const loggedInUser: AuthDetails = await authenticateAmplify();

      console.log(loggedInUser.username);
      console.log(awsRum);

      if (loggedInUser["username"] === "") {
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);

        // Set a UUID for this session (tab)
        let sessionUuid = sessionStorage.getItem('Kings Cross Session UUID');

        if(!sessionUuid){
          sessionUuid = String(uuidv4());
          sessionStorage.setItem('Kings Cross Session UUID', sessionUuid);
        }


        // This is a test of the IRIS mono api for websites
        if (loggedInUser.username === 'palcih'){
        

          sentryFetch(`https://alpha.api.iris.gsf.amazon.dev/common/container_metadata`,
          {
            method: 'GET',
            mode: "cors",
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
          })
          .then((response) => response.json())
          .then((data) => console.log(data));

          sentryFetch(`https://gamma.api.iris.gsf.amazon.dev/common/container_metadata`,
          {
            method: 'GET',
            mode: "cors",
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
          })
          .then((response) => response.json())
          .then((data) => console.log(data));

          sentryFetch(`https://prod.api.iris.gsf.amazon.dev/common/container_metadata`,
          {
            method: 'GET',
            mode: "cors",
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
          })
          .then((response) => response.json())
          .then((data) => console.log(data));
          
        }

        if (loggedInUser.username && awsRum && stage !== 'dev') {
          console.log("User is logged in, adding to RUM session attribute");
          awsRum.addSessionAttributes({
              username: loggedInUser.username
          });
          console.log(awsRum);
      }
      }
    }
    if (isLoggedIn === false) {
      authUser();
    } 

    // eslint-disable-next-line
  }, [])
  if (isLoggedIn) {
    return OBMMRoutes
  } else {
    return <p>.</p>
  }
}

export default App;
