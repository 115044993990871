import React from "react";
import { useLocation } from 'react-router-dom';
import { rolledFreightColor,  nearCPTColor, nyrOver,  ambientColor, chilledColor, frozenColor} from "./Constants";
import {Analytics} from "aws-amplify";
import { authenticateAmplify } from "./auth/midwayAuth";
import type { AuthDetails } from "./auth/midwayAuth";
import sentryFetch from "@amzn/sentry-fetch";

function CPTChaser() {
    return (
        <React.Fragment>
            <div className="legend">
                <div className="container" style={{display: "flex", justifyContent: "flex-end"}}>
                    <p className="m-0 text-center text-black">
                        <div> <p style={{backgroundColor: nearCPTColor, color: nearCPTColor}}>&#9632;</p> <p>CPT within 2 Hrs</p></div>
                        
                    </p>
                    <div>&emsp; </div>
                    <p className="m-0 text-center text-black">
                        <div> <p style={{backgroundColor: rolledFreightColor, color: rolledFreightColor}}>&#9632;</p> <p>Rolled Freight</p></div> &emsp; 
                        
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}

function Notifications() {
    return (
        <React.Fragment>
            <div className="legend">
                <div className="container" style={{display: "flex", justifyContent: "flex-end"}}>
                    <p className="m-0 text-center text-black">
                        <div> <p style={{backgroundColor: rolledFreightColor, color: rolledFreightColor}}>&#9632;</p> <p>Rolled Freight</p></div> &emsp; 
                        
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}

function RolledFreight() {
    return (
        <React.Fragment>
            <div className="legend">
                <div className="container" style={{display: "flex", justifyContent: "flex-end"}}>
                    <p className="m-0 text-center text-black">
                        <div> <p style={{backgroundColor: rolledFreightColor, color: rolledFreightColor}}>&#9632;</p> <p>Rolled Freight</p></div> &emsp; 
                        
                    </p>
                    <div>&emsp; </div>
                    <p className="m-0 text-center text-black">
                        <div> <p style={{backgroundColor: nyrOver, color: nyrOver}}>&#9632;</p> <p>NYR Exceeded</p></div> &emsp; 
                        
                    </p>
                    <div>&emsp; </div>
                    <p className="m-0 text-center text-black">
                        <div> <p style={{backgroundColor: ambientColor, color: ambientColor}}>&#9632;</p> <p>Ambient</p></div> &emsp; 
                        
                    </p>
                    <div>&emsp; </div>
                    <p className="m-0 text-center text-black">
                        <div> <p style={{backgroundColor: chilledColor, color: chilledColor}}>&#9632;</p> <p>Chilled</p></div> &emsp; 
                        
                    </p>
                    <div>&emsp; </div>
                    <p className="m-0 text-center text-black">
                        <div> <p style={{backgroundColor: frozenColor, color: frozenColor}}>&#9632;</p> <p>Frozen</p></div> &emsp; 
                        
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}

function NoLegend() {
    return (
        <React.Fragment>
            <div className="legend">
                <div className="container" style={{display: "flex", justifyContent: "flex-end"}}>
                </div>
            </div>
        </React.Fragment>
    );
}

function determineStage() {
    let stage = 'prod';

    if (window.location.hostname.includes('dev-dsk') || window.location.hostname.includes('aka.corp') || window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) {
    stage = 'dev';
    } else if (window.location.hostname.includes('beta')) {
    stage = 'beta';
    }

    return stage
}


function Legend(): JSX.Element {
    const pathName = useLocation().pathname;
    const location = pathName.split('/');
    const stage = determineStage();

    // Use gamma for beta and use prod if prod.
    const iris_api_stage = stage === 'prod' ? 'prod' : 'gamma'

    console.log(pathName)

    const authUser = async () => {
        const loggedInUser: AuthDetails = await authenticateAmplify();
        // Gathers usage of site and sends as event for monitoring.
        Analytics.record({
            name: 'usage',
            attributes: {
                alias: loggedInUser.username || 'unknown',
                url: pathName,
                warehouse_id: location.length >= 2 ? location[1] : '',
                page: location.length >= 3 ? location[2] : '',
                temp_zone: location.length >= 4 ? location[3] : ''
            },
            immediate: true
        });


        // Send usage data to iris_tool_usage_tracker
        // Only send if the page is one of the 3 pages we care about
        let pages_to_monitor = ['cptChaser', 'rolledFreight', 'notifications']
        if(stage !== 'dev' && pages_to_monitor.some(page => pathName.includes(page))){
            let body = {
                alias: loggedInUser.username || 'unknown',
                iam_auth_caller_arn: null,
                iam_auth_caller: null,
                application: 'Kings Cross',
                aws_region: 'us-east-1',
                metric_name: `Page View (${location[2]})`,
                metric_value: "1",
                session: sessionStorage.getItem('Kings Cross Session UUID'),
                stage: stage

            };

            sentryFetch(`https://${iris_api_stage}.api.iris.gsf.amazon.dev/common/iris_tool_usage_tracker`,
            {
            method: 'POST',
            mode: "cors",
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((data) => console.log(data));
    
        }
    }

    authUser();



    console.log(location);

  
    if(location.includes('cptChaser')){
        return  CPTChaser();
    } else if(location.includes('rolledFreight')) {
        return RolledFreight();
    } else if(location.includes('notifications')) {
        return Notifications();
    } else {
        return NoLegend()
    }
}

export default Legend;