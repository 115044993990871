import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link"
import chevronDownSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-down-small";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";

import { MainAPI } from '../MainApi';
import { rolledFreightColor} from "../Constants";

function NotificationDetailsHeader({ metricHeader }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell>Pallet Id</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>{metricHeader}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function NotificationDetailsRows({ data, metricKey }) {
    return (
        <React.Fragment>
            {data.map((pallet, index) => {
                console.log(pallet);
                const palletId = pallet["container"] ? pallet["container"] : "";
                const metric = pallet[metricKey] ? pallet[metricKey] : "";
                const destination = pallet["destination"] ? pallet["destination"] : "";
                const rolledFreight = pallet["rolled_freight"] ? pallet["rolled_freight"] : false;
                const backgroundColor = rolledFreight ? rolledFreightColor : null;
                return (
                    <React.Fragment key={palletId}>
                        <TableRow>
                            <TableCell backgroundColor={backgroundColor}>{palletId}</TableCell>
                            <TableCell backgroundColor={backgroundColor}>{destination}</TableCell>
                            <TableCell backgroundColor={backgroundColor}>{metric}</TableCell>
                        </TableRow>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
}

function NotificationDetailsTable({ data, metricKey, metricHeader }) {
    return (
        <Table headerRows={1} showDividers={true} rowComponents={[NotificationDetailsHeader, NotificationDetailsRows]}>
            <NotificationDetailsHeader metricHeader={metricHeader} />
            <NotificationDetailsRows data={data} metricKey={metricKey} />
        </Table>
    );
}

function NotificationProcessing({ data, metricKey, metricHeader, expandedName, expandedRows, updateExpandedRows }) {
    return (
        <Table>
            {data.map((areaData, index) => {
                const area = areaData["area"] ? areaData["area"] : "?";
                const pallets = areaData["pallets"] ? areaData["pallets"] : [];
                return (
                    <React.Fragment key={area}>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(expandedName + area) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(expandedName + area)}
                                >
                                    {expandedRows.includes(expandedName + area) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell>{area}</TableCell>
                        </TableRow>
                        {
                            expandedRows.includes(expandedName + area) && (
                                <TableRow>
                                    <NotificationDetailsTable data={pallets} metricHeader={metricHeader} metricKey={metricKey} />
                                </TableRow>
                            )
                        }
                    </React.Fragment>
                );
            })}
        </Table>
    );
}

function Notification({ data, metricKey, metricHeader, expandedName, expandedRows, updateExpandedRows }) {
    return (
        <Table>
            {data.map((areaData, index) => {
                const processingType = areaData["processing_type"] ? areaData["processing_type"] : "?";
                const areas = areaData["areas"] ? areaData["areas"] : [];
                return (
                    <React.Fragment key={processingType}>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(expandedName + processingType) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(expandedName + processingType)}
                                >
                                    {expandedRows.includes(expandedName + processingType) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell>{processingType}</TableCell>
                        </TableRow>
                        {
                            expandedRows.includes(expandedName + processingType) && (
                                <TableRow>
                                    <NotificationProcessing data={areas} metricHeader={metricHeader} metricKey={metricKey} expandedName={expandedName} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows} />
                                </TableRow>
                            )
                        }
                    </React.Fragment>
                );
            })}
        </Table>
    );
}

function TruckNotification({ data, metricKey, metricHeader, expandedName, expandedRows, updateExpandedRows }) {
    return (
        <Table>
            {data.map((vrid_data, index) => {
                const vrid = vrid_data["vrid"] ?? "Unknown";
                const predicted_pallets = vrid_data["predicted_pallets"] ?? 0;
                const palletized_pallets = vrid_data["palletized_pallets"] ?? 0;
                const nyp_pallets = predicted_pallets - palletized_pallets;
                const cases = vrid_data["cases"] ?? "Unknown";
                const cases_per_pallet = vrid_data["cases_per_pallet"] ?? "Unknown";
                const cpp_source = vrid_data["cpp_source"] ?? "Unknown";
                return (
                    <React.Fragment key={vrid}>
                        <TableRow>
                            <TableCell alignmentHorizontal="center">VRID</TableCell>
                            <TableCell alignmentHorizontal="center">CPP Source</TableCell>
                            <TableCell alignmentHorizontal="center">NYP Cases</TableCell>
                            <TableCell alignmentHorizontal="center">Cases Per Pallet (CPP)</TableCell>
                            <TableCell alignmentHorizontal="center">NYP Pallets</TableCell>
                            <TableCell alignmentHorizontal="center">Palletized Pallets</TableCell>
                            <TableCell alignmentHorizontal="center">Total Available Pallets</TableCell>
                            <TableCell alignmentHorizontal="center">SOP Link</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{vrid}</TableCell>
                            <TableCell>{cpp_source}</TableCell>
                            <TableCell>{cases}</TableCell>
                            <TableCell>{cases_per_pallet}</TableCell>
                            <TableCell>{nyp_pallets}</TableCell>
                            <TableCell>{palletized_pallets}</TableCell>
                            <TableCell>{predicted_pallets}</TableCell>
                            <TableCell><Link href="https://start.wwops.amazon.dev/boards/18163/261469">SOP</Link></TableCell>
                        </TableRow>
                    </React.Fragment>
                );
            })}
        </Table>
    );
}

function TableHeader() {
    return (
        <TableRow>
            <TableCell />
            <TableCell>CPT</TableCell>
            <TableCell>Lane</TableCell>
            <TableCell>Temp Zone</TableCell>
            <TableCell>Notification</TableCell>
        </TableRow>
    );
}

function NotificationsRows({ notifications }) {
    const [expandedRows, setExpandedRows] = useState([]);
    const updateExpandedRows = row => {
        const newExpandedRows = expandedRows.includes(row)
            ? expandedRows.filter(expandedRow => expandedRow !== row)
            : [...expandedRows, row];
        setExpandedRows(newExpandedRows);
    };

    const metricKeys = {
        "Pallet Merger": "total_pallet_height",
        "Ambient to Chilled": "case_quantity",
        "Frozen Out of Temp": "dwell_minutes",
        "Truck Cancellation": "vrid"
    };
    const metricHeaders = {
        "Pallet Merger": "Pallet Height",
        "Ambient to Chilled": "Case QTY",
        "Frozen Out of Temp": "Dwell Minutes",
        "Truck Cancellation": "vrid"
    };

    return (
        <React.Fragment>
            {notifications.map((notificationData, index) => {
                const cpt = notificationData["cpt_local"] || "";
                const lane = notificationData["lane"] || "";
                const tempZone = notificationData["temp_zone"] || "";
                const notificationName = notificationData["notification"] || "";
                const data = notificationData["data"] || [];
                const expandedName = [cpt, lane, tempZone, notificationName].join("-");
                const metricKey = metricKeys[notificationName] || "case_quantity";
                const metricHeader = metricHeaders[notificationName] || "Case QTY";

                let tableRow;

                if (notificationName === "Pallet Merger") {
                    console.log("Pallet Merger Data:", data);

                    // Traverse the nested structure to count opportunities by destination
                    const destinationCounts = {};
                    data.forEach(processingTypeData => {
                        processingTypeData.areas.forEach(areaData => {
                            areaData.pallets.forEach(pallet => {
                                const destination = pallet.destination || "Unknown";
                                if (!destinationCounts[destination]) {
                                    destinationCounts[destination] = 0;
                                }
                                destinationCounts[destination]++;
                            });
                        });
                    });

                    tableRow = (
                        <TableRow key={`${expandedName}-details`}>
                            <TableCell colSpan={5}>
                                {Object.entries(destinationCounts).map(([destination, count]) => (
                                    <div key={destination}>Destination: {destination} - Opportunities: {count}</div>
                                ))}
                            </TableCell>
                        </TableRow>
                    );
                } else if (notificationName === "Truck Cancellation") {
                    tableRow = (
                        <TableRow key={expandedName}>
                            <TruckNotification
                                key={expandedName}
                                data={data}
                                metricHeader={metricHeader}
                                metricKey={metricKey}
                                expandedName={expandedName}
                                expandedRows={expandedRows}
                                updateExpandedRows={updateExpandedRows}
                            />
                        </TableRow>
                    );
                } else {
                    tableRow = (
                        <TableRow key={expandedName}>
                            <Notification
                                key={expandedName}
                                data={data}
                                metricHeader={metricHeader}
                                metricKey={metricKey}
                                expandedName={expandedName}
                                expandedRows={expandedRows}
                                updateExpandedRows={updateExpandedRows}
                            />
                        </TableRow>
                    );
                }

                return (
                    <React.Fragment key={expandedName}>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(expandedName) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(expandedName)}
                                >
                                    {expandedRows.includes(expandedName) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell>{cpt}</TableCell>
                            <TableCell>{lane}</TableCell>
                            <TableCell>{tempZone}</TableCell>
                            <TableCell>{notificationName}</TableCell>
                        </TableRow>
                        {expandedRows.includes(expandedName) && tableRow}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
}

function Notifications() {
    const api = new MainAPI();
    const { warehouseId } = useParams();
    useEffect(() => {
        setNotifications([]);
    }, []);

    useEffect(() => {
        const fetchRolledFreight = async () => {
            const data = await api.getNotifications(warehouseId);
            if (data) {
                setNotifications(data);
            }
        };
        fetchRolledFreight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseId]);

    const [notifications, setNotifications] = useState([]);
    return (
        <Table headerRows={1} showDividers={true} rowComponents={[TableHeader, NotificationsRows]}>
            <TableHeader />
            <NotificationsRows notifications={notifications} />
        </Table>
    );
}

export default Notifications;