import { useEffect, useState } from "react";
import { useLocation, Link , useParams } from 'react-router-dom';
import { MainAPI } from '../MainApi';

function createAppButton(location, tempZone) {
    return (
        <Link to={ location + '/' + tempZone }>
            <button className="click">{ tempZone }<br/></button>
        </Link>
    )
}

function CPTChaserTempZone() {
    const api = new MainAPI();
    const { warehouseId } = useParams();

    useEffect(() => {
        setTempZones([]);
    }, []);

    useEffect(() => {

        const fetchTempZones = async () => {
            const data = await api.getTempZones(warehouseId);
            if (data) {
                setTempZones(data);
            }
        }
        fetchTempZones();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseId]);

    const [tempZones, setTempZones] = useState<Object[]>([]);
    const currentRoute = useLocation().pathname;
    let Buttons: any[] = [];
    for (let tempZone of tempZones) {
        Buttons.push(createAppButton(currentRoute, tempZone));
    }
    return (

        <div className={"container-fluid App"}>{ Buttons }</div>
    )
}

export default CPTChaserTempZone;
