import React from "react";

const IRISWiki = "https://w.amazon.com/bin/view/GSF/SupplyChain/IRIS/"
const OnCallSim = "https://sim.amazon.com/issues/create?template=c17ca04c-5840-4a2e-ada5-3026b4e7b7b5"
const StartPage = "https://start.wwops.amazon.dev/boards/22520/277705"

function Footer() {
  return (
    <div className="footer">
      <footer className="py-3 bg-light fixed-bottom">
        <div className="container">
          <p className="m-0 text-center text-black">
              Developed by: <a href={ IRISWiki } target="_blank" rel="noreferrer">gsf-iris</a> &emsp; Report an Issue: <a href={ OnCallSim } target="_blank" rel="noreferrer">Sim Template</a> &emsp; User Guide: <a href={ StartPage } target="_blank" rel="noreferrer">Link</a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;