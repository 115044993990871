import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { MainAPI } from '../MainApi';
import CPTChaserTable from './cptChaserTable';


function CPTChaserCPTList() {
    const api = new MainAPI();
    const { warehouseId, tempZone } = useParams();
    useEffect(() => {
        setcpts([]);
    }, []);

    useEffect(() => {
        const fetchcpts = async () => {
            const data = await api.getCPTs(warehouseId, tempZone);
            if (data) {
                setcpts(data);
            }
        }
        fetchcpts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseId, tempZone]);

    const [cpts, setcpts] = useState<Object[]>([]);
    // setInterval(() => fetchcpts(), 300000);
    return CPTChaserTable(cpts)
}

export default CPTChaserCPTList;
