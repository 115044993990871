import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import Table, { TableRow, TableCell } from "@amzn/meridian/table";

import { MainAPI } from '../MainApi';


function TableHeader() {
    return (
        <TableRow>
            <TableCell>VRID</TableCell>
            <TableCell>Dock Door Requested</TableCell>
            <TableCell>Lane</TableCell>
            <TableCell>SCAC</TableCell>
            <TableCell>Equipment Type</TableCell>
            <TableCell>Trailer Id</TableCell>
            <TableCell>Temp Zone</TableCell>
            <TableCell>Request Time</TableCell>
        </TableRow>
    )
}

function DockDoorRequestsRows({dockDoorRequests}) {
    return (
        <React.Fragment>
            {dockDoorRequests.map((dockDoorRequest, index)=>{
                const vrid = dockDoorRequest["vrid"] ? dockDoorRequest["vrid"] : ""
                const dockDoor = dockDoorRequest["dock_door"] ? dockDoorRequest["dock_door"] : ""
                const lane = dockDoorRequest["lane"] ? dockDoorRequest["lane"] : ""
                const scac = dockDoorRequest["scac"] ? dockDoorRequest["scac"] : ""
                const equipmentType = dockDoorRequest["equipment_type"] ? dockDoorRequest["equipment_type"] : ""
                const trailerId = dockDoorRequest["trailer_id"] ? dockDoorRequest["trailer_id"] : ""
                const tempZone = dockDoorRequest["temp_zone"] ? dockDoorRequest["temp_zone"] : ""
                const requestTime = dockDoorRequest["request_time"] ? dockDoorRequest["request_time"] : ""
                return (
                    <React.Fragment>
                        <TableRow>
                            <TableCell>{vrid}</TableCell>
                            <TableCell>{dockDoor}</TableCell>
                            <TableCell>{lane}</TableCell>
                            <TableCell>{scac}</TableCell>
                            <TableCell>{equipmentType}</TableCell>
                            <TableCell>{trailerId}</TableCell>
                            <TableCell>{tempZone}</TableCell>
                            <TableCell>{requestTime}</TableCell>
                        </TableRow>
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    )
}


function DockDoorRequests() {
    const api = new MainAPI();
    const { warehouseId } = useParams();
    useEffect(() => {
        setDockDoorRequests([]);
    }, []);

    useEffect(() => {
        const fetchDockDoorRequests = async () => {
            const data = await api.getDockDoorRequests(warehouseId);
            if (data) {
                setDockDoorRequests(data);
            }
        }
        fetchDockDoorRequests();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseId]);

    const [dockDoorRequests, setDockDoorRequests] = useState<Object[]>([]);
    console.log(dockDoorRequests);
    return (
        <Table headerRows={1} showDividers={true} rowComponents={[TableHeader, DockDoorRequestsRows]}>
            <TableHeader/>
            <DockDoorRequestsRows dockDoorRequests={dockDoorRequests}/>
        </Table>
    )
}

export default DockDoorRequests;