import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { MainAPI } from './MainApi';
import '../App.css';

function createWarehouseButton(warehouseId) {
    return (
        <Link to={ "/" + warehouseId }>
            <button className="click p-5 ms-3">{ warehouseId }</button>
        </Link>
    )
}

function OBMMHome() {
  const api = new MainAPI();
  const [warehouseIds, setWarehouseIds] = useState([]);

  useEffect(() => {
    setWarehouseIds([]);
  }, []);

  useEffect(() => {
    const fetchWarehouseIds = async () => {
      const data = await api.getWarehouseIds();
      if (data) {
        setWarehouseIds(data.map((d) => d));
      }
    }
    fetchWarehouseIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let Buttons: any[] = [];
  for (let key in warehouseIds) {
    Buttons.push(createWarehouseButton(warehouseIds[key]));
  }
  return (
    <div className="App">
        Welcome! Please select a building<br/>
        {Buttons}
    </div>
  );
}

export default OBMMHome;