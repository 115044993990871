import React, { useState } from "react";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link"
import chevronDownSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-down-small";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";
import plusTokens from "@amzn/meridian-tokens/base/icon/plus";
import minusTokens from "@amzn/meridian-tokens/base/icon/minus";

import CPTChaserDockDoorModal from "./cptChaserDockDoorModal";
import CPTChaserTrailerTempModal from "./cptChaserTrailerTempModal";
import { rolledFreightColor,  nearCPTColor} from "../Constants";

const currentUnix = Math.floor(Date.now()/1000);

function create_destination(lane){
    return lane.split("->").slice(1);
}

function create_notification_expand(notificationData, cptName){

    const notificationMapping = {
        "Pallet Merger": "-PalletMerger-",
        "Ambient to Chilled": "-AmbientToChilled-",
        "Frozen Out of Temp": "-FrozenOutOfTemp-",
        "Truck Cancellation": "-TruckCancellation-"
    }

    let output = new Set()

    // Iterate all notifications
    for(let notification of notificationData){
        const notificationMap = notificationMapping[notification.notification]

        if(notification.notification === "Truck Cancellation"){
            continue
            
        } else {

            // If the notification has pallets then iterate those
            for(let pallet of notification.pallets){
                // Pallets have multiple areas and this is what is used to expand rows

                for(let area of pallet.areas){
                    const pallet_area = area.area ? area.area : "?"

                    // Notification
                    let expandRowString = cptName + ' ' + notification.notification
                    output.add(expandRowString)

                    // Area
                    expandRowString = cptName + ' ' + notification.notification + ' ' + pallet.processing_type
                    output.add(expandRowString)

                    // Pallet
                    expandRowString = cptName + notificationMap + pallet_area
                    output.add(expandRowString)

                }
            }
        }
    }

    output = Array.from(output)

    if(output.length > 0){
        console.log(output)
    }

    return output
}

function PalletHeader({containerHeader}) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{containerHeader}</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Case QTY</TableCell>
            </TableRow>
        </React.Fragment>
    )
};

function PalletRows({palletData}) {
    return (
        <React.Fragment>
            {palletData.map((pallet, index)=> {
                    const palletId = pallet["container"] ? pallet["container"] : "";
                    const caseQTY = pallet["case_quantity"] ? pallet["case_quantity"] : 0;
                    const destination = pallet["destination"] ? pallet["destination"] : "";
                    const rolledFreight = pallet["rolled_freight"] ? pallet["rolled_freight"] : false;
                    const backgroundColor = rolledFreight ? rolledFreightColor : null;
                    return (
                        <React.Fragment>
                            <TableRow>
                                <TableCell backgroundColor={backgroundColor}>{palletId}</TableCell>
                                <TableCell backgroundColor={backgroundColor}>{destination}</TableCell>
                                <TableCell backgroundColor={backgroundColor}>{caseQTY}</TableCell>
                            </TableRow>
                        </React.Fragment>
                    )
                })}
        </React.Fragment>
    )
};

function PalletTable({palletData, containerHeader = "Pallet ID"}) {
    return (
        <Table headerRows={1} showDividers={true} rowComponents={[PalletHeader, PalletRows]}>
            <PalletHeader containerHeader={containerHeader}/>
            <PalletRows palletData={palletData}/>
        </Table>
    )
};


function LoadedHeader() {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell>Pallet ID</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Temp Zone</TableCell>
                <TableCell>Case QTY</TableCell>
            </TableRow>
        </React.Fragment>
    )
};

function LoadedRows({palletData}) {
    return (
        <React.Fragment>
            {palletData.map((pallet, index)=> {
                    const palletId = pallet["container"] ? pallet["container"] : "";
                    const caseQTY = pallet["case_quantity"] ? pallet["case_quantity"] : 0;
                    const tempZone = pallet["temp_zone"] ? pallet["temp_zone"] : "";
                    const destination = pallet["destination"] ? pallet["destination"] : "";
                    const rolledFreight = pallet["rolled_freight"] ? pallet["rolled_freight"] : false;
                    const backgroundColor = rolledFreight ? rolledFreightColor : null;
                    return (
                        <React.Fragment>
                            <TableRow>
                                <TableCell backgroundColor={backgroundColor}>{palletId}</TableCell>
                                <TableCell backgroundColor={backgroundColor}>{destination}</TableCell>
                                <TableCell backgroundColor={backgroundColor}>{tempZone}</TableCell>
                                <TableCell backgroundColor={backgroundColor}>{caseQTY}</TableCell>
                            </TableRow>
                        </React.Fragment>
                    )
                })}
        </React.Fragment>
    )
};

function LoadedTable({palletData}) {
    return (
        <Table headerRows={1} showDividers={true} rowComponents={[LoadedHeader, LoadedRows]}>
            <LoadedHeader/>
            <LoadedRows palletData={palletData}/>
        </Table>
    )
};

function VRIDDetails(vridData, warehouseId, expandedRows, updateExpandedRows, lane) {
    const vridName = vridData["vrid"] ? vridData["vrid"] : "";
    const trailerId = vridData["trailer_id"] ? vridData["trailer_id"] : "";
    const status = vridData["status"] ? vridData["status"] : "";
    const scac = vridData["scac"] ? vridData["scac"] : "";
    const trailerType = vridData["trailer_type"] ? vridData["trailer_type"] : "";
    const tempZone = vridData["temp_zone"] ? vridData["temp_zone"] : "";
    const maxPalletSpace = vridData["max_pallet_space"] ? vridData["max_pallet_space"] : 0
    const loadedPallets = vridData["pallet_quantity"] ? vridData["pallet_quantity"] : 0
    const [dockDoor, setDockDoor] = useState(vridData["dock_door"] ? vridData["dock_door"] : "");
    const [currentTrailerTemp, setCurrentTrailerTemp] = useState(vridData["recorded_temp"] ? vridData["recorded_temp"] : "");
    let TEMPAudit = null;
    if (["CHILLED", "FROZEN"].some(t => tempZone.includes(t))) {
        TEMPAudit = (
            <TableRow>
                <TableCell>Recorded Temperature</TableCell>
                <TableCell>{currentTrailerTemp} <CPTChaserTrailerTempModal vrid={vridName} warehouseId={warehouseId} setCurrentTrailerTemp={setCurrentTrailerTemp}/></TableCell>
            </TableRow>
        )
    };

    return (
        <Table>
          <TableRow>
              <TableCell>VRID</TableCell>
              <TableCell>{vridName}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Trailer ID</TableCell>
              <TableCell>{trailerId}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>{status}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Dock Door</TableCell>
              <TableCell>{dockDoor} <CPTChaserDockDoorModal vrid={vridName} warehouseId={warehouseId} setCurrentDockDoor={setDockDoor}/></TableCell>
          </TableRow>
          <TableRow>
              <TableCell>SCAC</TableCell>
              <TableCell>{scac}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Trailer Type</TableCell>
              <TableCell>{trailerType}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Temp Zone</TableCell>
              <TableCell>{tempZone}</TableCell>
          </TableRow>
            {TEMPAudit}
          <TableRow>
              <TableCell>Max Pallet Space</TableCell>
              <TableCell>{maxPalletSpace}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Total Loaded Pallets</TableCell>
              <TableCell>{loadedPallets}</TableCell>
          </TableRow>
          <TableRow>

            <TableCell
                alignmentVertical="top"
                rowSpan={expandedRows.includes(vridName + "-Loaded") ? 2 : 1}
            >
                <Button
                    type="icon"
                    size="small"
                    onClick={() => updateExpandedRows(vridName + "-Loaded")}
                >
                    {expandedRows.includes(vridName + "-Loaded") ? (
                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                    ) : (
                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                    )}
                </Button>
            </TableCell>
            <TableCell>
                Loaded Pallets
            </TableCell>
        </TableRow>
        {
            expandedRows.includes(vridName + "-Loaded") && (

                <TableRow><LoadedTable palletData={vridData["pallets"]}/></TableRow>
            )
        }
        </Table>
    )
};

function VRIDS({vridData, expandedRows, updateExpandedRows, warehouseId, cptName, lane}) {
    return (
        <React.Fragment>
        {vridData.map((vrid, index)=> {
                let DropArrow = null;
                if (index === 0) {
                    DropArrow = (
                        <React.Fragment>
                            <TableRow>
                                <TableCell
                                        alignmentVertical="top"
                                        rowSpan={expandedRows.includes("VRID" + cptName) ? vridData.length + 1 : 1}
                                    >
                                        <Button
                                            type="icon"
                                            size="small"
                                            onClick={() => updateExpandedRows("VRID" + cptName)}
                                        >
                                            {expandedRows.includes("VRID" + cptName) ? (
                                                <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                            ) : (
                                                <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                            )}
                                        </Button>
                                    </TableCell>
                                    <TableCell>Total VRIDS - {vridData.length}</TableCell>
                            </TableRow>
                        </React.Fragment>
                    )
                };
                return (
                    <React.Fragment>
                        {DropArrow}
                        {
                            expandedRows.includes("VRID" + cptName) && (
                                <React.Fragment>
                                    <TableRow>{VRIDDetails(vrid, warehouseId, expandedRows, updateExpandedRows, lane)}</TableRow>
                                </React.Fragment>
                            )
                        }
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    )
};

function VRIDHeader() {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell></TableCell>
                <TableCell header={true}>VRID</TableCell>
            </TableRow>
        </React.Fragment>
    )
};

function NYRHeader() {
    return (
        <TableRow><TableCell/><TableCell header={true}>Destination NYR</TableCell></TableRow>
    )
}

function NYR({destinations, cptName, expandedRows, updateExpandedRows, nyrData}) {
    return (
        <React.Fragment>
        {destinations.map((destination, index)=> {
            const destinationNYRData = nyrData[destination] ? nyrData[destination] : {}
            const destinationNYR = destinationNYRData["nyr"] ? destinationNYRData["nyr"] : {};
            const destinationLoaded = destinationNYRData["loaded"] ? destinationNYRData["loaded"] : {};
            const ambientNYR = destinationNYR["ambient"] ? destinationNYR["ambient"] : "N/A";
            const ambientLoaded = destinationLoaded["ambient"] ? destinationLoaded["ambient"] : 0;
            const ambientBackground = ambientLoaded > ambientNYR ? nearCPTColor : null
            const chilledNYR = destinationNYR["chilled"] ? destinationNYR["chilled"] : "N/A";
            const chilledLoaded = destinationLoaded["chilled"] ? destinationLoaded["chilled"] : 0;
            const chilledBackground = chilledLoaded > chilledNYR ? nearCPTColor : null
            const frozenNYR = destinationNYR["frozen"] ? destinationNYR["frozen"] : "N/A";
            const frozenLoaded = destinationLoaded["frozen"] ? destinationLoaded["frozen"] : 0;
            const frozenBackground = frozenLoaded > frozenNYR ? nearCPTColor : null
            const nyrTableName = destination + "-" + cptName;
            return(
                <React.Fragment>
                    <TableRow>
                                <TableCell
                                    alignmentVertical="top"
                                    rowSpan={expandedRows.includes(nyrTableName) ? 2 : 1}
                                >
                                    <Button
                                        type="icon"
                                        size="small"
                                        onClick={() => updateExpandedRows(nyrTableName)}
                                    >
                                        {expandedRows.includes(nyrTableName) ? (
                                            <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                        ) : (
                                            <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                        )}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    {destination}
                                </TableCell>
                            </TableRow>
                            {
                                expandedRows.includes(nyrTableName) && (
                                    <TableRow>
                                        <Table headerRows={1}>
                                            <TableRow>
                                                <TableCell>Temp Zone</TableCell>
                                                <TableCell>NYR</TableCell>
                                                <TableCell>Loaded</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell backgroundColor={ambientBackground}>Ambient</TableCell>
                                                <TableCell backgroundColor={ambientBackground}>{ambientNYR}</TableCell>
                                                <TableCell backgroundColor={ambientBackground}>{ambientLoaded}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell backgroundColor={chilledBackground}>Chilled</TableCell>
                                                <TableCell backgroundColor={chilledBackground}>{chilledNYR}</TableCell>
                                                <TableCell backgroundColor={chilledBackground}>{chilledLoaded}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell backgroundColor={frozenBackground}>Frozen</TableCell>
                                                <TableCell backgroundColor={frozenBackground}>{frozenNYR}</TableCell>
                                                <TableCell backgroundColor={frozenBackground}>{frozenLoaded}</TableCell>
                                            </TableRow>
                                        </Table>
                                    </TableRow>
                                )
                            }
                </React.Fragment>
            )})}
             </React.Fragment>
    )

}

function VRID(vridData, expandedRows, updateExpandedRows, warehouseId, cptName, lane, nyrData) {
    const destinations = create_destination(lane);
    return (
        <Table headerRows={1} showDividers={true} rowComponents={[VRIDHeader, VRIDS, NYRHeader, NYR]}>
            <VRIDHeader/>
            <VRIDS vridData={vridData} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows} warehouseId={warehouseId} cptName={cptName} lane={lane}/>
            <NYRHeader/>
            <NYR destinations={destinations} cptName={cptName} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows} nyrData={nyrData}/>
        </Table>
    )
};

function ProcessingDetails(cptName, processingData, expandedRows, updateExpandedRows, containerHeader = "Pallet ID") {
    return (
        <Table>
            {Object.keys(processingData).map((processingArea, index) => {
                const processingAreaRow = processingArea + "-" + cptName;
                return (
                <React.Fragment>
                    <TableRow>
                        <TableCell
                            alignmentVertical="top"
                            rowSpan={expandedRows.includes(processingAreaRow) ? 2 : 1}
                        >
                            <Button
                                type="icon"
                                size="small"
                                onClick={() => updateExpandedRows(processingAreaRow)}
                            >
                                {expandedRows.includes(processingAreaRow) ? (
                                    <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                ) : (
                                    <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                )}
                            </Button>
                        </TableCell>
                        <TableCell>
                            {processingArea}
                        </TableCell>
                    </TableRow>
                    {
                        expandedRows.includes(processingAreaRow) && (
                            <TableRow><PalletTable palletData={processingData[processingArea]["pallets"]} containerHeader={containerHeader}/></TableRow>
                        )
                    }
                </React.Fragment>
                )
            })}
        </Table>
    )
};

function Processing(cptName, stackedData, stagedData, pteData, crossdockData, expandedRows, updateExpandedRows) {
    return (
        <Table headerRows={1} showDividers={true}>
          <TableRow>
              <TableCell></TableCell>
              <TableCell header={true}>Processing</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              alignmentVertical="top"
              rowSpan={expandedRows.includes(cptName +"-Stacked") ? 2 : 1}
            >
              <Button
                  type="icon"
                  size="small"
                  onClick={() => updateExpandedRows(cptName +"-Stacked")}
              >
                  {expandedRows.includes(cptName +"-Stacked") ? (
                      <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                  ) : (
                      <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                  )}
              </Button>
            </TableCell>
            <TableCell>Stacked</TableCell>
          </TableRow>
            {
                expandedRows.includes(cptName +"-Stacked") && (
                    <TableRow>{ProcessingDetails(cptName, stackedData, expandedRows, updateExpandedRows)}</TableRow>
                )
            }


            <TableRow>
                <TableCell
                    alignmentVertical="top"
                    rowSpan={expandedRows.includes(cptName +"-Staged") ? 2 : 1}
                >
                    <Button
                        type="icon"
                        size="small"
                        onClick={() => updateExpandedRows(cptName +"-Staged")}
                    >
                        {expandedRows.includes(cptName +"-Staged") ? (
                            <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                        ) : (
                            <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                        )}
                    </Button>
                </TableCell>
                <TableCell>Staged</TableCell>
            </TableRow>
            {
                expandedRows.includes(cptName +"-Staged") && (
                    <TableRow>{ProcessingDetails(cptName, stagedData, expandedRows, updateExpandedRows)}</TableRow>
                )
            }

            <TableRow>
                <TableCell
                    alignmentVertical="top"
                    rowSpan={expandedRows.includes(cptName +"-Pte") ? 2 : 1}
                >
                    <Button
                        type="icon"
                        size="small"
                        onClick={() => updateExpandedRows(cptName +"-Pte")}
                    >
                        {expandedRows.includes(cptName +"-Pte") ? (
                            <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                        ) : (
                            <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                        )}
                    </Button>
                </TableCell>
                <TableCell>PTE</TableCell>
            </TableRow>
            {
                expandedRows.includes(cptName +"-Pte") && (
                    <TableRow>{ProcessingDetails(cptName, pteData, expandedRows, updateExpandedRows)}</TableRow>
                )
            }

            <TableRow>
                <TableCell
                    alignmentVertical="top"
                    rowSpan={expandedRows.includes(cptName +"-Crossdock") ? 2 : 1}
                >
                    <Button
                        type="icon"
                        size="small"
                        onClick={() => updateExpandedRows(cptName +"-Crossdock")}
                    >
                        {expandedRows.includes(cptName +"-Crossdock") ? (
                            <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                        ) : (
                            <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                        )}
                    </Button>
                </TableCell>
                <TableCell>Crossdock</TableCell>
            </TableRow>
            {
                expandedRows.includes(cptName +"-Crossdock") && (
                    <TableRow>{ProcessingDetails(cptName, crossdockData, expandedRows, updateExpandedRows,  "Container ID")}</TableRow>
                )
            }
        </Table>
    )
};

function findPickedAreas(pickData) {
    let picked = [];
    for (let pick in pickData) {
        if (!pick.startsWith("P-")) {
            picked.push({"drop_zone": pick, "pallets": pickData[pick]["pallets"]})
        };
    }
    return picked
};

function PickingRows({pickData, pickingCases, cptName, expandedRows, updateExpandedRows}) {
    const dropZoneData = findPickedAreas(pickData);
    return (
        <React.Fragment>
            <TableRow>
                <TableCell/>
                <TableCell>Not yet picked - {pickingCases}</TableCell>
            </TableRow>
            {dropZoneData.map((dropZone, index)=> {
                const dropZoneName = dropZone["drop_zone"];
                const pallets = dropZone["pallets"];
                return (
                    <React.Fragment>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(cptName + " " + dropZoneName) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(cptName + " " + dropZoneName)}
                                >
                                    {expandedRows.includes(cptName + " " + dropZoneName) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell>{dropZoneName}</TableCell>
                        </TableRow>
                        {
                            expandedRows.includes(cptName + " " + dropZoneName) && (
                                <TableRow><PalletTable palletData={pallets}/></TableRow>
                            )
                        }
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    )
};

function PickingHeader() {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell/>
                <TableCell header={true}>Pick Area</TableCell>
            </TableRow>
        </React.Fragment>
    )
};

function Picking(pickData, pickingCases, cptName, expandedRows, updateExpandedRows) {
    return (
       <Table headerRows={1} showDividers={true} rowComponents={[PickingHeader, PickingRows]}>
          <PickingHeader/>
           <PickingRows pickData={pickData} pickingCases={pickingCases} cptName={cptName} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows}/>
        </Table>

    )
};

function PalletMergerDetailsHeader() {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell>Pallet Id</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Pallet Height</TableCell>
            </TableRow>
        </React.Fragment>
    )
};

function PalletMergerDetailsRows({palletData}) {
    return (
        <React.Fragment>
            {palletData.map((pallet, index)=> {
                    const palletId = pallet["container"] ? pallet["container"] : ""
                    const palletHeight = pallet["total_pallet_height"] ? pallet["total_pallet_height"] : 0
                    const destination = pallet["destination"] ? pallet["destination"] : ""
                    const rolledFreight = pallet["rolled_freight"] ? pallet["rolled_freight"] : false
                    const backgroundColor = rolledFreight ? rolledFreightColor : null
                    return (
                        <React.Fragment>
                            <TableRow>
                                <TableCell backgroundColor={backgroundColor}>{palletId}</TableCell>
                                <TableCell backgroundColor={backgroundColor}>{destination}</TableCell>
                                <TableCell backgroundColor={backgroundColor}>{palletHeight}</TableCell>
                            </TableRow>
                        </React.Fragment>
                    )
                })}
        </React.Fragment>
    )
};

function PalletMergerDetails(palletData) {
    return (
        <Table headerRows={1} showDividers={true} rowComponents={[PalletMergerDetailsHeader, PalletMergerDetailsRows]}>
            <PalletMergerDetailsHeader />
            <PalletMergerDetailsRows palletData={palletData}/>
        </Table>
    )
};

function PalletMergerNotification({palletMergerData, cptName, expandedRows, updateExpandedRows}) {
    return (
        <Table>
            {palletMergerData.map((palletMerger, index)=> {
                const area = palletMerger["area"] ? palletMerger["area"] : "?"
                const pallets = palletMerger["pallets"] ? palletMerger["pallets"] : []
                return (
                    <React.Fragment>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(cptName + "-PalletMerger-" + area) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(cptName + "-PalletMerger-" + area)}
                                >
                                    {expandedRows.includes(cptName + "-PalletMerger-" + area) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell>{area}</TableCell>
                        </TableRow>
                        {
                            expandedRows.includes(cptName + "-PalletMerger-" + area) && (
                                <TableRow>{PalletMergerDetails(pallets)}</TableRow>
                            )
                        }
                    </React.Fragment>
                )

            })}
        </Table>
    )
};


function AmbientToChilledNotification({ambientToChilledData, cptName, expandedRows, updateExpandedRows}) {
    return (
        <Table>
            {ambientToChilledData.map((ambientToChilled, index)=> {
                const area = ambientToChilled["area"] ? ambientToChilled["area"] : "?"
                const pallets = ambientToChilled["pallets"] ? ambientToChilled["pallets"] : []
                return (
                    <React.Fragment>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(cptName + "-AmbientToChilled-" + area) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(cptName + "-AmbientToChilled-" + area)}
                                >
                                    {expandedRows.includes(cptName + "-AmbientToChilled-" + area) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell>{area}</TableCell>
                        </TableRow>
                        {
                            expandedRows.includes(cptName + "-AmbientToChilled-" + area) && (
                                <TableRow><PalletTable palletData={pallets}/></TableRow>
                            )
                        }
                    </React.Fragment>
                )

            })}
        </Table>
    )
};

function FrozenOutOfTempDetailsHeader() {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell>Pallet Id</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Dwell Minutes</TableCell>
            </TableRow>
        </React.Fragment>
    )
};

function FrozenOutOfTempDetailsRows({palletData}) {
    return (
        <React.Fragment>
            {palletData.map((pallet, index)=> {
                    const palletId = pallet["container"] ? pallet["container"] : ""
                    const palletHeight = pallet["dwell_minutes"] ? pallet["dwell_minutes"] : 0
                    const destination = pallet["destination"] ? pallet["destination"] : 0
                    const rolledFreight = pallet["rolled_freight"] ? pallet["rolled_freight"] : false
                    const backgroundColor = rolledFreight ? rolledFreightColor : null
                    return (
                        <React.Fragment>
                            <TableRow>
                                <TableCell backgroundColor={backgroundColor}>{palletId}</TableCell>
                                <TableCell backgroundColor={backgroundColor}>{destination}</TableCell>
                                <TableCell backgroundColor={backgroundColor}>{palletHeight}</TableCell>
                            </TableRow>
                        </React.Fragment>
                    )
                })}
        </React.Fragment>
    )
};

function FrozenOutOfTempDetails(palletData) {
    return (
        <Table headerRows={1} showDividers={true} rowComponents={[FrozenOutOfTempDetailsHeader, FrozenOutOfTempDetailsRows]}>
            <FrozenOutOfTempDetailsHeader />
            <FrozenOutOfTempDetailsRows palletData={palletData}/>
        </Table>
    )
};

function FrozenOutOfTempNotification({frozenOutOfTempData, cptName, expandedRows, updateExpandedRows}) {
    return (
        <Table>
            {frozenOutOfTempData.map((frozenOutOfTemp, index)=> {
                const area = frozenOutOfTemp["area"] ? frozenOutOfTemp["area"] : "?"
                const pallets = frozenOutOfTemp["pallets"] ? frozenOutOfTemp["pallets"] : []
                return (
                    <React.Fragment>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(cptName + "-FrozenOutOfTemp-" + area) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(cptName + "-FrozenOutOfTemp-" + area)}
                                >
                                    {expandedRows.includes(cptName + "-FrozenOutOfTemp-" + area) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell>{area}</TableCell>
                        </TableRow>
                        {
                            expandedRows.includes(cptName + "-FrozenOutOfTemp-" + area) && (
                                <TableRow>{FrozenOutOfTempDetails(pallets)}</TableRow>
                            )
                        }
                    </React.Fragment>
                )

            })}
        </Table>
    )
};


function TruckCancellationNotification({truckCancellationData, cptName, expandedRows, updateExpandedRows}) {
    return (
        <Table>
            {[truckCancellationData].map((vrid_data, index)=> {
                const vrid = truckCancellationData["vrid"] ?? "Unknown"
                const predicted_pallets = truckCancellationData["predicted_pallets"] ?? 0
                const palletized_pallets = truckCancellationData["palletized_pallets"] ?? 0
                const nyp_pallets = predicted_pallets - palletized_pallets;
                const cases = vrid_data["cases"] ?? "Unknown"
                const cases_per_pallet = vrid_data["cases_per_pallet"] ?? "Unknown"
                const cpp_source = vrid_data["cpp_source"] ?? "Unknown"
                return (
                    <React.Fragment>
                        <TableRow>
                        <TableCell alignmentHorizontal="center">VRID</TableCell>
                        <TableCell alignmentHorizontal="center">CPP Source</TableCell>
                        <TableCell alignmentHorizontal="center">NYP Cases</TableCell>
                        <TableCell alignmentHorizontal="center">Cases Per Pallet (CPP)</TableCell>
                        <TableCell alignmentHorizontal="center">NYP Pallets</TableCell>
                        <TableCell alignmentHorizontal="center">Palletized Pallets</TableCell>
                        <TableCell alignmentHorizontal="center">Total Available Pallets</TableCell>
                        <TableCell alignmentHorizontal="center">SOP Link</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell>{vrid}</TableCell>
                        <TableCell>{cpp_source}</TableCell>
                        <TableCell>{cases}</TableCell>
                        <TableCell>{cases_per_pallet}</TableCell>
                        <TableCell>{nyp_pallets}</TableCell>
                        <TableCell>{palletized_pallets}</TableCell>
                        <TableCell>{predicted_pallets}</TableCell>
                        <TableCell><Link href="https://start.wwops.amazon.dev/boards/18163/261469 ">SOP</Link></TableCell>
                        </TableRow>
                    </React.Fragment>
                )
            })}
        </Table>
    )
};


function NotificationProcessing({processingData, cptName, expandedRows, updateExpandedRows, notificationTitle}) {
    console.log(processingData);
    return (
        <React.Fragment>
            {processingData.map((notification, index)=> {
                const processingType = notification["processing_type"] ? notification["processing_type"] : "?";
                const palletData = notification["areas"] ? notification["areas"] : [];
                let NotificationElement;
                if (notificationTitle === "Pallet Merger") {
                    NotificationElement = <PalletMergerNotification  palletMergerData={palletData} cptName={cptName} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows}/>
                }
                else if (notificationTitle === "Ambient to Chilled") {
                    NotificationElement = <AmbientToChilledNotification ambientToChilledData={palletData} cptName={cptName} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows}/>
                }

                else if (notificationTitle === "Frozen Out of Temp") {
                    NotificationElement = <FrozenOutOfTempNotification frozenOutOfTempData={palletData} cptName={cptName} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows}/>
                }
                else if (notificationTitle === "Truck Cancellation") {
                    NotificationElement = <TruckCancellationNotification truckCancellationData={notification} cptName={cptName} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows}/>
                }
                else {
                    NotificationElement = <PalletTable palletData={palletData}/>
                }

                return (
                    <React.Fragment>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(cptName + " " + notificationTitle + " " + processingType) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(cptName + " " + notificationTitle + " " + processingType)}
                                >
                                    {expandedRows.includes(cptName + " " + notificationTitle + " " + processingType) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell>{processingType}</TableCell>
                        </TableRow>
                        {
                            expandedRows.includes(cptName + " " + notificationTitle + " " + processingType) && (
                                <TableRow>
                                    {NotificationElement}
                                </TableRow>
                            )
                        }
                    </React.Fragment>
                )
                })}
        </React.Fragment>
    )
};

function NotificationRows({notificationData, cptName, expandedRows, updateExpandedRows}) {
    return (
        <React.Fragment>
            {notificationData.map((notification, index)=> {
                console.log(notification);
                const notificationTitle = notification["notification"] ? notification["notification"] : "?";
                const processingData = notification["pallets"] ? notification["pallets"] : [];

                return (
                    <React.Fragment>
                        <TableRow>
                            <TableCell
                                alignmentVertical="top"
                                rowSpan={expandedRows.includes(cptName + " " + notificationTitle) ? 2 : 1}
                            >
                                <Button
                                    type="icon"
                                    size="small"
                                    onClick={() => updateExpandedRows(cptName + " " + notificationTitle)}
                                >
                                    {expandedRows.includes(cptName + " " + notificationTitle) ? (
                                        <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                                    ) : (
                                        <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                                    )}
                                </Button>
                            </TableCell>
                            <TableCell>{notificationTitle}</TableCell>
                        </TableRow>
                        {
                            expandedRows.includes(cptName + " " + notificationTitle) && (
                                <TableRow>
                                    <NotificationProcessing processingData={processingData} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows} cptName={cptName} notificationTitle={notificationTitle}/>
                                </TableRow>
                            )
                        }
                    </React.Fragment>
                )
                })}
        </React.Fragment>
    )
};

function NotificationHeader() {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell/>
                <TableCell header={true}>Notification</TableCell>
            </TableRow>
        </React.Fragment>
    )
};

function Notifications(notificationData, cptName, expandedRows, updateExpandedRows) {
    return (
        <Table headerRows={1} showDividers={true} rowComponents={[NotificationHeader, NotificationRows]}>
          <NotificationHeader/>
          <NotificationRows notificationData={notificationData} cptName={cptName} expandedRows={expandedRows} updateExpandedRows={updateExpandedRows}/>
        </Table>
    )
};

function CPTRows({cptData}) {
    const [expandedRows, setExpandedRows] = useState([])
    const updateExpandedRows = row => {

        // Most calls to this is just a single row, but the added expand all sends multiple rows to show
        if (!Array.isArray(row)){
            row = [row]
        }

        console.log(`row: ${row}`)
        let outputRows = []

        // When expanding/hiding all rows of a CPT we might have a few rows that are not matching (all rows but 2 showing) due
        // to manual human hiding/showing. Instead check if any are in the expandedrows, if so then remove all else add all.
        if(row.length > 1){
            const contains = expandedRows.some(element => {
                return row.includes(element);
              });

            let newExpandedRows = []
            if(contains){
            newExpandedRows = expandedRows.filter(element => !row.includes(element));

            } else {
            newExpandedRows = [...expandedRows, ...row];
            }

            outputRows = newExpandedRows

        } else {
            const elementsToRemove = row.filter(element => expandedRows.includes(element));
            const elementsToInclude = row.filter(element => !expandedRows.includes(element));
            const newExpandedRows = expandedRows.filter(element => !elementsToRemove.includes(element));
            outputRows = [...newExpandedRows, ...elementsToInclude]
        }

        // console.log(`OuputRows: ${outputRows}`)


        setExpandedRows(outputRows)
    }
  return (
      <React.Fragment>
      {cptData.map((cpt, index)=>{
          const warehouseId = cpt["warehouse_id"] ? cpt["warehouse_id"] : ""
          const cptLocal = cpt["cpt_local"] ? cpt["cpt_local"] : "?";
          const cptName = cpt["cpt_name"] ? cpt["cpt_name"] + ' ' + cptLocal : "?";
          const lane = cpt["lane"] ? cpt["lane"] : "";
          const pickData = cpt["picking"] ? cpt["picking"] : [];
          const stackedData = cpt["stacked"] ? cpt["stacked"] : [];
          const stagedData = cpt["staged"] ? cpt["staged"] : [];
          const pteData = cpt["pte"] ? cpt["pte"] : [];
          const crossdockData = cpt["crossdock"] ? cpt["crossdock"] : [];
          const vridData = cpt["vrids"] ? cpt["vrids"]: [];
          const loadedPallets = cpt["loaded_pallets"] ? cpt["loaded_pallets"] : 0;
          const stagedPallets = cpt["staged_pallets"] ? cpt["staged_pallets"] : 0;
          const stackedPallets = cpt["stacked_pallets"] ? cpt["stacked_pallets"] : 0;
          const ptePallets = cpt["pte_pallets"] ? cpt["pte_pallets"] : 0;
          const crossdockContainers = cpt["crossdock_containers"] ? cpt["crossdock_containers"] : 0;
          const processingPallets = stagedPallets + stackedPallets + ptePallets + crossdockContainers
          const pickingPallets = cpt["picking_pallets"] ? cpt["picking_pallets"] : 0;
          const pickingCases = cpt["picking_cases"] ? cpt["picking_cases"] : 0;
          const notificationCount = cpt["notifications"] ? cpt["notifications"].length : 0
          const notificationData = cpt["notifications"] ? cpt["notifications"] : [];
          const cptUnix = cpt["cpt"] ? cpt["cpt"] : 0;
          const nyrData = cpt["nyr_data"] ? cpt["nyr_data"] : {};
          const backgroundColor = (cptUnix - currentUnix) <= (60 * 60 * 2) ? nearCPTColor : null
          const boldCell = (cptUnix - currentUnix) <= (60 * 60 * 2) ? true : false

        // Used to pass all the cpt rows to expand all
        // We need to replicate all code locations that call the function updateExpandedRows
        const destinations = create_destination(lane);
        const dropZoneData = findPickedAreas(pickData);

        const expand_data = [...[
            cptName,
            "VRID" + cptName, //VRIDs
            cptName +"-Stacked",
            cptName +"-Staged",
            cptName +"-Pte",
            cptName +"-Crossdock"
            ],
            ...vridData.map(el => el.vrid + "-Loaded"), //Loaded Pallets
            ...destinations.map(destination => destination + "-" + cptName), //nyrTableName
            ...Object.keys(stackedData).map(processingArea => processingArea + "-" + cptName), //Processed Stacked
            ...Object.keys(stagedData).map(processingArea => processingArea + "-" + cptName), //Processed Staged
            ...Object.keys(pteData).map(processingArea => processingArea + "-" + cptName), //Processed PTE
            ...Object.keys(crossdockData).map(processingArea => processingArea + "-" + cptName), //Processed Crossdock
            ...dropZoneData.map(dropZone => cptName + " " + dropZone.drop_zone), //nyrTableName,
            ...create_notification_expand(notificationData, cptName) // All notification types
        ]

          return (
              <React.Fragment>
                  <TableRow>
                    <TableCell
                      rowSpan={expandedRows.includes(cptName) ? 2 : 1}
                      alignmentVertical="top"
                    >
                      <Button
                        type="icon"
                        size="small"
                        onClick={() => updateExpandedRows(cptName)}
                      >
                        {expandedRows.includes(cptName) ? (
                          <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                        ) : (
                          <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                        )}
                      </Button>
                    </TableCell>
                    <TableCell
                      rowSpan={expandedRows.includes(cptName) ? 2 : 1}
                      alignmentVertical="top"
                      backgroundColor={backgroundColor}
                      header={boldCell}
                    >
                        <Column>{cptLocal}</Column>
                    </TableCell>
                    <TableCell
                        rowSpan={expandedRows.includes(cptName) ? 2 : 1}
                        alignmentVertical="top"
                    >
                      <Column>{lane}</Column>
                    </TableCell>
                    <TableCell alignmentVertical="top">
                      <Column>{loadedPallets}</Column>
                    </TableCell>
                    <TableCell alignmentVertical="top">
                        <Column>{processingPallets}</Column>
                      {/*  Bring this instead of above if crossdock containers need to be counted separate*/}
                      {/*<Column>Processing Pallets - {processingPallets}</Column>*/}
                      {/*<Column>Crossdock Containers - {crossdockContainers}</Column>*/}
                    </TableCell>
                    <TableCell alignmentVertical="top">
                      <Column>Picked Pallets - {pickingPallets}</Column>
                      <Column>NYP Cases - {pickingCases}</Column>
                    </TableCell>
                    <TableCell alignmentVertical="top">
                      <Column>{notificationCount}</Column>
                    </TableCell>
                    <TableCell
                      rowSpan={expandedRows.includes(cptName) ? 2 : 1}
                      alignmentVertical="top"
                    >
                      <Button
                        type="icon"
                        size="small"
                        onClick={() => updateExpandedRows(expand_data)}
                      >
                        {expandedRows.includes(cptName) ? (
                          <Icon tokens={minusTokens}>Collapse rows</Icon>
                        ) : (
                          <Icon tokens={plusTokens}>Expand rows</Icon>
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>

                  {expandedRows.includes(cptName) && (
                    <TableRow>
                      <TableCell alignmentVertical="top">
                        {VRID(vridData, expandedRows, updateExpandedRows, warehouseId, cptName, lane, nyrData)}
                      </TableCell>

                      <TableCell alignmentVertical="top">
                          {Processing(cptName, stackedData, stagedData, pteData, crossdockData, expandedRows, updateExpandedRows)}
                      </TableCell>

                      <TableCell alignmentVertical="top">
                          {Picking(pickData, pickingCases, cptName, expandedRows, updateExpandedRows)}
                      </TableCell>

                      <TableCell alignmentVertical="top">
                        {Notifications(notificationData, cptName, expandedRows, updateExpandedRows)}
                      </TableCell>
                    </TableRow>
                  )}
              </React.Fragment>)
          })}
      </React.Fragment>

  )
};

function TableHeader() {
    return (
        <TableRow>
        <TableCell width={60}>
          {/* Empty table cell to hold the expandable Icon button */}
        </TableCell>
        <TableCell header={true}>CPT</TableCell>
        <TableCell header={true}>Lane</TableCell>
        <TableCell header={true}>Loaded</TableCell>
        <TableCell header={true}>Processing</TableCell>
        <TableCell header={true}>Picking</TableCell>
        <TableCell header={true}>Notifications</TableCell>
        <TableCell header={true}>Expand/Hide All</TableCell>
      </TableRow>
    )
};

const CPTChaserTable = (cptData) => {
  return (
      <Table headerRows={1} showDividers={true} rowComponents={[TableHeader, CPTRows]}>
        <TableHeader/>
        <CPTRows cptData={cptData}/>
      </Table>
  )
};

export default CPTChaserTable;