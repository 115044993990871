import { useState, useCallback, useEffect } from "react";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import pencilTokens from "@amzn/meridian-tokens/base/icon/pencil";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input"

import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import { AlertType } from "@amzn/meridian/alert/alert";
import { MainAPI } from '../MainApi';


type AlertObject = {
    type: AlertType;
    message?: string;
    title?: string;
    isSet: boolean;
};


const CPTChaserDockDoorModal = ({vrid, warehouseId, setCurrentDockDoor}): JSX.Element => {
    const api = new MainAPI();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dockDoor, setDockDoor] = useState("");
    const [disableButton, setDisableButton] = useState(true);
    const [loader, setLoader] = useState(false);
    const [response, setResponse] = useState<AlertObject>({
        isSet: false,
        type: "success",
    });
    const onClickButton = useCallback(() => setOpen(true), []);

    const resetModalView = () => {
        setOpen(false);
        setDockDoor("");
        setResponse({
            isSet: false,
            type: "success",
        });
        setError(false);
    };
    //By default meridian have different functions for closing the modal so keeping it like that only
    const onClickFooterButton = useCallback(() => {
        resetModalView();
    }, []);

    const onClose = useCallback(() => {
        resetModalView();
    }, []);
    
    /*
      This useEffect is used to enable or disable the Confirm button
      */
    useEffect(() => {
        if (dockDoor !== "") setDisableButton(false);
        else setDisableButton(true);
    }, [dockDoor]);

    const submitDockDoorRequest = async () => {
        setLoader(true);
        setResponse({ ...response, isSet: false });

        api.requestDockDoor(warehouseId, vrid, dockDoor)
            .then((res) => {
                if (res.status === 206) {
                    setResponse({
                        ...response,
                        isSet: true,
                        type: "warning",
                        message:
                            "Below bins were not added successfully, please check the bin details and try again",
                    });
                } else {
                    setResponse({
                        ...response,
                        isSet: true,
                        type: "success",
                        message:
                            "Dock door successfully requested",
                    });
                    onClickFooterButton();
                    setCurrentDockDoor(dockDoor);
                }
            })
            .catch((exception) => {
                setResponse({
                    ...response,
                    isSet: true,
                    type: "error",
                    message: "error",
                });
                setError(true);
                setErrorMessage("Something went wrong!");
            })
            .finally(() => setLoader(false));
    };

    return (
        <>
            <Button
                size="small"
                minWidth="8%"
                onClick={onClickButton}
                data-testid="modalButton"
            >
                <Icon tokens={pencilTokens} />
            </Button>

            <Modal
                title={"Request Dock Door for VRID " + vrid}
                open={open}
                onClose={onClose}
                scrollContainer="viewport"
                closeLabel="Close"
                describedById="modal-description"
            >
                <Column spacingInset="small">
                    <Input
                        value={dockDoor}
                        onChange={setDockDoor}
                        type="text"
                        size="xlarge"
                        label="Dock Door"
                        placeholder="Enter Dock Door..."
                        error={error}
                        errorMessage={errorMessage}
                    />
                </Column>
                <ModalFooter>
                    <Row alignmentHorizontal="right" widths="fit">
                        <Button
                            type="secondary"
                            size="small"
                            onClick={onClickFooterButton}
                            data-testid="closeModel"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => submitDockDoorRequest()}
                            disabled={disableButton || loader}
                            data-testid="ConfirmButton"
                        >
                            Confirm
                        </Button>
                        {loader && <Loader size="small"></Loader>}
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default CPTChaserDockDoorModal;